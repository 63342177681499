<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h3>
                  Add staff
                </h3>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                <router-link to="/user/admin">
                  Staff
                </router-link>
                \
                Add
              </div>
              <!--                            <div class="col-2 ">-->
              <!--                                <router-link :to="{name:'slider'}" class="btn btn-primary">-->
              <!--                                    <i class="fas fa-arrow-left"></i>-->
              <!--                                    Back-->
              <!--                                </router-link>-->
              <!--                            </div>-->
              <!--                            <div class="col-4">-->
              <!--                                <h4>Add Slider Image</h4>-->
              <!--                            </div>-->
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    Personal Information <hr/>
                  </v-col>
                  <div class="col-md-12 form-group">
                    <v-select outlined dense :items="titles" clearable label="Title" item-text="text" item-value="value"
                              v-model="user.title"></v-select>
                    <span class="text-danger" v-if="$v.user.title.$error">This information is required</span>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-text-field v-model="user.first_name" outlined dense label="First name"></v-text-field>
                    <span class="text-danger" v-if="$v.user.first_name.$error">This information is required</span>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-text-field v-model="user.middle_name" outlined dense label="Middle name"></v-text-field>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-text-field v-model="user.last_name" outlined dense label="Last name"></v-text-field>
                    <span class="text-danger" v-if="$v.user.last_name.$error">This information is required</span>
                  </div>
                  <v-col cols="12">
                    Contact Information <hr/>
                  </v-col>
                  <div class="col-md-6 form-group">
                    <v-text-field type="text" v-model="user.mobile" outlined dense label="Mobile"></v-text-field>
                    <span class="text-danger" v-if="!this.id && $v.user.mobile.$error">This information is required</span>
                  </div>
                  <div class="col-md-6 form-group">
                    <v-text-field type="text" v-model="user.phone" outlined dense label="Phone"></v-text-field>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    Login Information <hr/>
                  </v-col>
                  <div class="col-md-12 form-group">
                    <v-text-field v-model="user.email" outlined dense label="Email" @blur="validateEmail"></v-text-field>
                    <span class="text-danger" v-if="!this.id && $v.user.email.$error">This information is required</span>
                    <span class="text-danger" v-if="email_exists">Email already taken</span>
                  </div>
                  <div class="col-md-12 form-group">
                    <v-switch label="Is this email address verified for login and communication purpose?" v-model="user.is_email_verified">
                    </v-switch>
                  </div>
                  <div class="col-md-6 form-group" v-if="!this.id">
                    <v-text-field type="password" v-model="user.password" outlined dense label="Password"></v-text-field>
                    <span class="text-danger" v-if=" !this.id  && $v.user.password.$error">This information is required</span>
                  </div>
                  <div class="col-md-6 form-group" v-if="!this.id">
                    <v-text-field type="password" v-model="user.confirm_password" outlined dense
                                  label="Confirm password"></v-text-field>
                    <span class="text-danger" v-if=" !this.id  && $v.user.confirm_password.$error">Password and confirm password must be same.</span>
                  </div>
                  <v-col cols="12">
                    Faculty Information <hr/>
                  </v-col>
                  <div class="col-md-6 form-group">
                    <v-select
                        multiple
                        chips
                        outlined
                        dense
                        label="Role"
                        class="form-control"
                        deletable-chips
                        clearable
                        v-model="user.type"
                        :items="job_types"
                        placeholder="Role"
                        item-value="name"
                        item-text="name"
                    ></v-select>
                    <span class="text-danger" v-if="$v.user.type.$error">This information is required</span>
                  </div>
                  <div class="col-md-6 form-group">
                    <v-select
                        outlined
                        dense
                        label="Access account type"
                        class="form-control"
                        v-model="user.account_type"
                        :items="account_types"
                        placeholder="Role"
                        clearable
                        item-value="value"
                        item-text="title"
                    ></v-select>
                  </div>
                  <div class="col-md-6 form-group">
                    <v-select
                        outlined
                        dense
                        v-model="department_ids"
                        :items="departments"
                        class="form-control"
                        multiple
                        small-chips
                        item-value="id"
                        item-text="title"
                        label="Department">
                    </v-select>
                  </div>
                  <div class="col-md-6 form-group">
                    <v-select
                        :items="programs"
                        item-value="id"
                        item-text="title"
                        v-model="program_ids"
                        multiple
                        small-chips
                        outlined
                        dense
                        label="Program">
                    </v-select>
                  </div>


                  <div class="col-md-12 form-group">
                      <v-switch
                          v-model="user.send_welcome_email"
                          label="Send a welcome email to staff with their login details?">
                      </v-switch>
                    </div>
                  <div class="col-md-4 form-group">
                      <v-switch label="Account status" v-model="user.is_active">
                      </v-switch>
                    </div>

                  <div class="col-md-12 form-group">

                    <!-- <span class="text-danger" v-if="$v.user.is_active.$error">Title is required</span> -->
                  </div>
                  <div class="col-12">
                    <img
                        v-if="user.image_path"
                        :src="user.image_path['thumb']"
                        alt
                        style="height:75px"
                        class="img-thumbnail"
                    />
                  </div>
                </v-row>
              </v-col>
            </div>


            <div class="col-12 mt-5">
              <div class="float-right">
                <v-btn
                    class="btn btn-standard text-gray"
                    depressed
                    @click="redirectTo"
                >Cancel
                </v-btn>
                <v-btn v-if="checkIsAccessible('user', 'create') || checkIsAccessible('user', 'edit')"
                       class="text-white ml-2 btn btn-primary"
                       depressed
                       @click="createOrUpdate"
                       :loading="isBusy"
                >Save
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import {required, email, sameAs} from "vuelidate/lib/validators";
import RoleService from "@/core/services/user/role/RoleService";
import DepartmentService from "@/core/services/department/DepartmentService";
import ProgramService from "@/core/services/level/program/ProgramService";

const roleService = new RoleService();
const userService = new UserService();
const departmentService = new DepartmentService();
const programService = new ProgramService();
export default {
  name: "staff-add",
  validations() {
    return {
      user: this.rules
    };
  },
  data() {
    return {
      titles: [
        {text: 'Mr', value: 'mr'},
        {text: 'Mrs', value: 'mrs'},
        {text: 'Ms', value: 'ms'},
        {text: 'Miss', value: 'miss'},
        {text: 'Dr.', value: 'dr'},
        {text: 'Other', value: 'other'},
      ],
      account_types: [
        {title: "Portal", value: "admin"},
        {title: "Teacher", value: "teacher"},
        {title: "Student", value: "student"},
        {title: "Library", value: "library"}
      ],
      isBusy: false,
      sizeExceed: false,
      departments: [],
      department_ids: [],
      email_exists: false,
      programs: [],
      program_ids: [],
      job_types: ["admin", "customer", "coordinator", "principle"],
      user: {
        title: '',
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        mobile: null,
        description: null,
        password: null,
        confirm_password: null,
        type: ['staff'],
        account_type: 'admin',
        is_email_verified: true,
        is_active: true,
        send_welcome_email:false,
      }
    };
  },

  mounted() {
    this.id ? this.getUser(this.id) : "";
    this.getRoles();
    this.getDepartments();
    this.getPrograms();
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    department_ids_converted() {
      return this.department_ids.join();
    },
    rules() {
      let rule = {
        title: {required},
        first_name: {required},
        last_name: {required},
        type: {required}
      };
      if (!this.id) {
        rule.email = {required};
        rule.mobile = {required};
        rule.password = {required};
        rule.confirm_password = {required, sameAsPassword: sameAs('password')};
      }
      return rule;
    }
  },
  methods: {
    getUser(id) {
      userService.show(id).then(response => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        this.edit = true;
      });
    }, getRoles() {
      roleService.all().then(response => {
        let types = response.data.data
        types.map(ele => {
          if (ele.name != 'student' && ele.name != 'teacher')
            this.job_types.push(ele)
        })
      });
    },
    getDepartments() {
      departmentService.paginate().then(response => {
        this.departments = response.data.data;
      });
    },
    redirectTo() {
      this.$router.push({name: "user", params: {type: "admin"}});
    },
    saveUser() {
      this.isBusy = true;
      this.user.department_id = this.department_ids_converted;
      userService
          .createByAdmin(this.user)
          .then(response => {
            if (!response.data.status) {
              this.isBusy = false;
              this.$snotify.error(response.data.msg)
              return
            } else {
              this.syncPrograms(response.data.newUser.id);
            }
            this.isBusy = false;
            this.$snotify.success("User added to the system");
            this.$tabs.close().then(response => {
              this.$router.push({name: "user", params: {type: "admin"}});
            });

          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    createOrUpdate() {
      this.$v.user.$touch();
      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          delete this.user.password
          this.updateUser();
        } else {
          this.saveUser();
        }
      }
    },
    updateUser() {
      this.isBusy = true;
      delete this.user.email;
      delete this.user.phone;
      this.user.department_id = this.department_ids_converted;
      userService
          .updateUser(this.user.id, this.user)
          .then(response => {
            this.isBusy = false;
            this.syncPrograms(this.id);
            this.$snotify.success("User information updated");
            this.$tabs.close().then(response => {
              this.$router.push({name: "user", params: {type: "admin"}});
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something went wrong");
          });
    },
    validateEmail() {
      // 'user/validate-email'
      let data = {
        email: this.user.email
      }
      userService.validateEmail(data).then(res => {
        // console.log(res)
        if (res.data.status == 'OK' && res.data.user) {
          this.email_exists = true;
          this.$snotify.error('Email already in use. Please try again with different email. ')
        } else {
          this.email_exists = false;
        }
      })
    },

    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },

    getAssignedPrograms() {
      userService.getAssignedPrograms(this.id).then((res) => {
        this.program_ids = res.data.programs.map(program => {
          return program.id;
        })
      }).catch(err => {
      })
    },

    syncPrograms(userId) {
      if (this.program_ids.length) {
        let data = this.program_ids.map((program_id) => {
          return {
            program_id: program_id,
            user_id: userId,
          }
        })
        userService.syncPrograms(userId, data).then(() => {})
      }
    },
  }
};
</script>
